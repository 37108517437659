import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";

import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import {setLoading, hideLoading, setVATRate, updateCountry, updateProcessedSales} from "../../redux/actions.js";
import CountrySettings from "../../country-settings.js";
import {humanDate} from "../utils.js";

import { calculateVAT } from "../../core/vat-functions.js";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        boxSizing: "border-box",
    },
    makeFlex: {
        display: "flex"
    },
    flex: {
        flexGrow: 1,
        marginTop: 0,
        paddingRight: theme.spacing.unit * 2
    },
    padding: {
        //paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    },
    paddingTop: {
        paddingTop: theme.spacing.unit * 2
    },
    green: {
        color: "green"
    },
    red: {
        color: "red"
    },
    orange: {
        color: "orange"
    },
    bold: {
        fontWeight: "bold"
    },
    title: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    }
});

function toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class CountryBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            results: [],
            countryDSTs: []
        }
    }

    render() {
        const { data, jurisdictions, classes, departureCountry, country, date, stats, showOnlyExceedingDST, showClose } = this.props;

        let gross = 10;
        let depGross = 0;
        let net = 0;
        let dstNet = 0;

        let vat = 0;
        let dstVat = 0;
        let dstVal = CountrySettings.getDST( country.CODE, date );
        let dstPerc = 0;

        let dst = null;

        if (!data.calcs) {

        } else {
            let depData = data.calcs[departureCountry.CURRENCY];
            let arrData = data.calcs[country.CURRENCY];

            gross = arrData.gross;
            depGross = depData.gross;

            net = arrData.net;
            dstNet = arrData.dstNet || 0;

            vat = depData.vat;
            dstVat = arrData.dstVat;

            dst = arrData.dstCrossedDate;
        }

        let totalNet = net + dstNet;
        let distance = dstVal - totalNet;
        dstPerc = Math.floor( (totalNet / dstVal) * 100 );
        let show = showOnlyExceedingDST ? dst : true;

        function ShowTotals() {
            if (!data.calcs)
                return null;

            return Object.keys(data.calcs).map(x => {
                let d = data.calcs[x];
                return <div class="total">
                            <Typography variant="body2">
                                Gross Sales ({x}): {toCommas(d.gross.toFixed(2))}
                            </Typography>
                            <Typography variant="body2">
                                Net Sales ({x}): {toCommas((d.net + (d.dstNet || 0)).toFixed(2))}
                            </Typography>
                      </div>
            });
        }

        function ShowJuris() {
            if (!jurisdictions)
                return null;

            return jurisdictions.map(x => {
                let name = x.country.NAME;
                return Object.keys(x.calcs).map(currency => {
                    let d = x.calcs[currency];
                    return <div class="total">
                                <Typography variant="body2">
                                    Gross Sales to {name} ({currency}): {toCommas(d.gross.toFixed(2))}
                                </Typography>
                                <Typography variant="body2">
                                    Net Sales to {name} ({currency}): {toCommas((d.net + (d.dstNet || 0)).toFixed(2))}
                                </Typography>
                          </div>
                });
            });
        }

        function ShowDST() {
            if (country.CODE != "ROW") {
                return <Typography variant="body2">
                    DST: { dstVal } {country.CURRENCY}
                </Typography>
            }
            return null;
        }

        function ShowDSTCrossed() {
            if (country.CODE != "ROW" && dst && departureCountry.CODE != country.CODE) {
                return <Typography variant="body2" className={[classes.bold].join(" ")}>
                    Date crossed DST: <span className={classes.red}>{humanDate(dst)}</span>
                </Typography>
            } else {
                return null;
            }
        }

        function ShowDSTState() {
            if (showClose && country.CODE != "ROW" && departureCountry.CODE != country.CODE) {
                let colour = classes.green;
                if (dstPerc >= 100) {
                    colour = classes.red;
                } else if (dstPerc >= 70) {
                    colour = classes.orange;
                }
                return (<>
                            <Typography variant="body2">Distance to DST: <span className={colour}>{toCommas(distance.toFixed(2))} {country.CURRENCY}</span>
                            </Typography>
                            <Typography variant="body2">Percent of DST Reached: <span className={colour + " " + classes.bold}>{dstPerc}%</span>
                            </Typography>
                        </>)
            } else {
                return null;
            }
        }

        function ShowVATOwed() {
            if (country.CODE != "ROW" && departureCountry.CODE != country.CODE) {
                return <Typography variant="body2">
                            VAT owed to {country.CODE}: {toCommas(dstVat.toFixed(2))} {country.CURRENCY}
                        </Typography>
            } else {
                return null;
            }
        }

        if (show) {
            return <Grid className={classes.padding+ " country"} item="item" xs={12} sm={6} md={4} lg={3}>
                        <Typography className={classes.title} variant="h5">
                            {country.NAME}
                        </Typography>
                        <Typography variant="body2">
                            Number of sales: { stats.numberOfTransactions }
                        </Typography>
                        <Typography variant="body2">
                            Number of stock movements: { stats.numberOfStockMovements }
                        </Typography>
                        {ShowDST()}
                        {ShowTotals()}
                        {ShowJuris()}
                        <Typography variant="body2">
                            VAT owed to {departureCountry.CODE}: { toCommas(vat.toFixed(2)) } {departureCountry.CURRENCY}
                        </Typography>
                        {ShowVATOwed()}
                        {ShowDSTState()}
                        { ShowDSTCrossed() }
                    </Grid>
        } else {
            return null;
        }
    }
}

const mapStateToProps = (state, ownProps) => {
    return {showOnlyExceedingDST: state.showOnlyExceedingDST};
}

export default connect(mapStateToProps, null)(withStyles(styles)(CountryBox));
