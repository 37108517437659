import CountrySettings from "../country-settings.js";
import Customizer from "./Customizer.js";

function initCountries(country, initData, results) {
    let res = [];
    let c = CountrySettings[country];

    if (c && c.JURIS) {
        if (!results[c.JURIS]) {
            results[c.JURIS] = {};
            Object.assign(results[c.JURIS], initData);
        }

        res.push(results[c.JURIS]);
    }

    if (!results[country]) {
        results[country] = {};
        Object.assign(results[country], initData);
    }

    res.push(results[country]);

    return res;
}

export default class Summary extends Customizer {
    constructor() {
        super();

        this.years = {};
        this.currentYear = {};
        this.currentDepartureCountry = {};
        this.currentArrivalCountries = [];
    }

    visitYear(year) {
        if (!this.years[year])
            this.years[year] = { months: {}, countryInfo: {} };

        this.currentYear = this.years[year];
    }

    visitDepartureCountry(dep) {
        if (!this.currentYear.countryInfo[dep])
            this.currentYear.countryInfo[dep] = {
                minStockInDate: null
            };
        
        this.depC = dep;
        this.currentDepartureCountry = this.currentYear.countryInfo[dep];
    }

    visitArrivalCountry(arr) {
        this.currentArrivalCountries = initCountries(arr, { 
            minDate: null, 
            maxDate: null, 
            minSaleDate: null, 
            maxSaleDate: null, 
            minStockInDate: null,
            minStockOutDate: null,
            numberOfTransactions: 0,
            numberOfStockMovements: 0 }
        , this.currentDepartureCountry);
    }

    visitDate(date) {
        let mon = date.getMonth();

        if (!this.currentYear.months[mon])
            this.currentYear.months[mon] = true;
    }

    visit({ departureCountry, arrivalCountry, type, date, file }) {
        for (var c of this.currentArrivalCountries) {
            if (type === "INBOUND" || type === "FC_TRANSFER") {
                if (c.minStockOutDate === null || date < c.minStockOutDate)
                    c.minStockOutDate = date;

                c.numberOfStockMovements++;

                if (!this.currentYear.countryInfo[arrivalCountry]) {
                    this.currentYear.countryInfo[arrivalCountry] = {
                        minStockInDate: date
                    };
                } else if (date < this.currentYear.countryInfo[arrivalCountry].minStockInDate) {
                    this.currentYear.countryInfo[arrivalCountry].minStockInDate = date;
                }
            }

            if (type === "SALE" || type === "REFUND") {
                if (c.minSaleDate === null || date < c.minSaleDate)
                    c.minSaleDate = date;

                if (c.maxSaleDate === null || date > c.maxSaleDate)
                    c.maxSaleDate = date;

                c.numberOfTransactions++;
            }
        }
    }
}
