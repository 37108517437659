import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";

import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import CustomTableCell from "./CustomTableCell.jsx";

import {setLoading, hideLoading, setVATRate, updateCountry, updateProcessedSales} from "../../../redux/actions.js";
import CountrySettings from "../../../country-settings.js";
import { humanDate } from "../../utils.js";

import { calculateVAT } from "../../../core/vat-functions.js";

const styles = theme => ({
});

class CountryStock extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { data, classes } = this.props;

        return <TableRow>
                  <CustomTableCell>{ data.name }</CustomTableCell>
                  <CustomTableCell>{ data.destination }</CustomTableCell>
                  <CustomTableCell>{ data.currency }</CustomTableCell>
                  <CustomTableCell>{ data.dst }</CustomTableCell>
                  <CustomTableCell>{ data.netSales }</CustomTableCell>
                  <CustomTableCell><b>{ data.dateCrossed }</b></CustomTableCell>
                </TableRow>
    }
}

export default connect(null, null)(withStyles(styles)(CountryStock));
