import React from 'react';
import ReactDOM from 'react-dom';
import {connect} from "react-redux";

import {withStyles} from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Divider from '@material-ui/core/Divider';

import {setLoading, hideLoading, setVATRate, updateCountry, updateProcessedSales} from "../../redux/actions.js";
import CountrySettings from "../../country-settings.js";
import {humanDate} from "../utils.js";

import { calculateVAT } from "../../core/vat-functions.js";

import CountryBox from "./CountryBox.jsx";
import HeadedPaper from '../paper/HeadedPaper.jsx';
import AppbarTextField from "../appbar/AppbarTextField.jsx";

const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2,
        marginBottom: theme.spacing.unit * 2,

        boxSizing: "border-box",
    },
    makeFlex: {
        display: "flex"
    },
    flex: {
        flexGrow: 1,
        marginTop: 0,
        paddingRight: theme.spacing.unit * 2,
        color: "white"
    },
    padding: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    },
    paddingTop: {
        paddingTop: theme.spacing.unit * 2
    },
    green: {
        color: "green"
    },
    red: {
        color: "red"
    },
    orange: {
        color: "orange"
    },
    bold: {
        fontWeight: "bold"
    },
    title: {
        paddingTop: theme.spacing.unit * 2,
        paddingBottom: theme.spacing.unit * 2
    },
    inputRoot: {
        color: "inherit"
    }
});

const getDST = (countryCode, year) => {
    let country = CountrySettings[countryCode];
    if (country.YEARS) {
        for (let yea of Object.keys(country.YEARS).map(x => parseInt(x)).sort((a,b) => b - a)) {
            if (year <= yea) {
                return country.YEARS["" + year];
            }
        }
    }

    return country.DST;
}

function toCommas(value) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

class Country extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            results: [],
            countryDSTs: []
        }
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.info.CODE != nextProps.info.CODE) {
            this.setState({customVAT: nextProps.info.VAT});
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.vatRate != this.props.vatRate) {
            this.props.updateCountry(this.props.rawData, this.props.info, this.props.vatRate, this.props.date);
        }
    }

    formatCountries() {
        const { classes, info, calculations, stats, years, date } = this.props;

        let countriesInData = Object.keys(calculations);

        return countriesInData
                .map(c => ({calcs: calculations[c], stats: stats[c] }))
                .map(c => <CountryBox 
                    showClose={date != "TOTAL" || years.length == 1} 
                    stats={ c.stats } 
                    date={ date } 
                    departureCountry={ info } 
                    country={ c.calcs.mainland.country } 
                    data={ c.calcs.mainland } 
                    jurisdictions={ c.calcs.jurisdictions } 
                /> );
    }

    render() {
        const {info, classes, calculations, stats} = this.props;

        if (!calculations)
            return null;

        let countries = this.formatCountries();

        function reducer(acc, val) {
            let gross = 0;
            let net = 0;
            let vat = 0;

            if (val.mainland) {
                let total = val.mainland.calcs[info.CURRENCY];
                gross = total.gross;
                net = total.net + (total.dstNet || 0);
                vat = total.vat + (total.dstVat || 0);

                let dateOfFirstSale = stats[val.mainland.country.CODE].minSaleDate;
                let dateOfFirstStockOut = stats[val.mainland.country.CODE].minStockOutDate;

                if (dateOfFirstSale && (acc.dateOfFirstSale === null || dateOfFirstSale < acc.dateOfFirstSale)) {
                    acc.dateOfFirstSale = dateOfFirstSale;
                }

                if (dateOfFirstStockOut && (acc.dateOfFirstStockOut === null || dateOfFirstStockOut < acc.dateOfFirstStockOut)) {
                    acc.dateOfFirstStockOut = dateOfFirstStockOut;
                }
            }

            acc.gross += gross;
            acc.net += net;
            acc.vat += vat;

            return acc;
        }

        let reduced = Object.keys(calculations).map(c => calculations[c]).reduce(reducer, {dateOfFirstSale: null, dateOfFirstStockOut: null, gross: 0, net: 0, vat: 0});

        function OutputDate() {
            if (reduced.dateOfFirstSale) {
                return <Typography className={classes.paddingTop} variant="h6">
                    Date of first sale: { humanDate(reduced.dateOfFirstSale) }
                </Typography>
            } else {
                return "";
            }
        }

        function OutputStockInDate() {
            if (stats.minStockInDate) {
                return <Typography variant="h6">
                    Date of first stock movement in: { humanDate(stats.minStockInDate) }
                </Typography>
            } else {
                return "";
            }
        }

        function OutputStockOutDate() {
            if (reduced.dateOfFirstStockOut) {
                return <Typography variant="h6">
                    Date of first stock movement out: { humanDate(reduced.dateOfFirstStockOut) }
                </Typography>
            } else {
                return "";
            }
        }

        if (countries.find(x => typeof x != 'undefined')) {
            return <HeadedPaper title={"From " + info.NAME} className={classes.root} elevation={1} headerControls={
                <AppbarTextField id="name"
                           placeholder="Custom VAT Rate"
                           className={classes.flex}
                           margin="normal"
                           type="number"
                           value={this.props.vatRate}
                           onChange={e => {
                    let vat = parseFloat(e.target.value);

                    this.props.setVATRate(this.props.info.CODE, vat);
                }}/>
                }>

                <Grid container="container" spacing={0}>
                    {countries}
                </Grid>

                <Divider/>

                { OutputDate() }
                {OutputStockInDate()}
                {OutputStockOutDate()}

                <Typography variant="h6">Gross sales: {toCommas(reduced.gross.toFixed(2))} {info.CURRENCY}</Typography>
                <Typography variant="h6">Net sales: {toCommas(reduced.net.toFixed(2))} {info.CURRENCY}</Typography>
                <Typography variant="h6">Total VAT owed: {toCommas(reduced.vat.toFixed(2))} {info.CURRENCY}</Typography>
            </HeadedPaper>
        } else {
            return null;
        }
    }
}

/*
<Typography variant="title" component="h1">Gross sales: {info.SYMBOL}{toCommas(grossSalesFrom.toFixed(2))}</Typography>
<Typography variant="title" component="h1">Net sales: {info.SYMBOL}{toCommas(netSalesFrom.toFixed(2))}</Typography>
<Typography variant="title" component="h1">Total VAT owed: {info.SYMBOL}{toCommas((grossSalesFrom - netSalesFrom).toFixed(2))}</Typography>
*/

const mapStateToProps = (state, ownProps) => {
    let v = state.vatRates[ownProps.info.CODE];
    if (typeof v == 'undefined')
        v = ownProps.info.VAT;

    return {showOnlyExceedingDST: state.showOnlyExceedingDST};
};

const mapDispatchToProps = dispatch => {
    return {
        setVATRate: (country, rate) => dispatch(setVATRate(country, rate)),
        updateCountry: (data, info, rate, date) => dispatch(updateCountry(data, info, rate, date)),
        updateProcessedSales: () => dispatch(updateProcessedSales())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Country));
